import Layout from "../components/Layout";
import React, { useState } from "react";
import { Input, Button, notification } from "antd";
import axios from "axios";
import { useStaticQuery, graphql } from "gatsby";
import TCCSupportEmailLink from "../components/TCCSupportEmailLink";
import TCCSupportPhoneLink from "../components/TCCSupportPhoneLink";
import { RightCircleOutlined, MailOutlined, PhoneOutlined, HomeOutlined } from "@ant-design/icons";
import tccNotification from "../utils/tccNotification";

export default () => {
  const { TextArea } = Input;
  const SUPPORT_KEY = "TCC Support";
  const { site } = useStaticQuery(
    graphql`
      query($key: String) {
        site(siteMetadata: { emailAddresses: { elemMatch: { name: { eq: $key } } } }) {
          siteMetadata {
            phoneNumber
            address {
              companyName
              addressLine1
              addressLine2
              state
              zipCode
            }
            emailAddresses {
              configuration {
                address
                text
              }
            }
          }
        }
      }
    `,
    { key: SUPPORT_KEY }
  );

  const { siteMetadata } = site;

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const displayNotificationUsing = (notificationArguments) => {
    notification.open(notificationArguments);
  };

  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thanks", "Your message has been received and we will be in touch as soon as possible")
      : tccNotification.buildFailedNotificationArgsFrom("There was an error", "Thank you for trying to submit your message. It seems there was a problem that was likely our fault. Please try again.");
    displayNotificationUsing(notificationArguments);

    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
      form.elements.email.value = "";
      form.elements.fullname.value = "";
      form.elements.message.value = "";
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/b39b0733-c96a-46c5-8a8d-56582968020c",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Done", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Layout pageTitle="Contact Us To Help You Save Time &amp; Money">
      <section className="relative text-gray-700 body-font">
        <div className="container px-2 mx-auto mt-5 sm:flex-no-wrap">
          <h1 className="px-2 lg:px-0">Contact Us</h1>
          {/* <h3 className="text-blue-500">Please use the details below to get in touch. We’ll aim to get back to you within two working days. </h3> */}
          <p className="text-blue-500">
            At The Comparison Company, we want to positively impact you and your family by providing you with the information you need to be an informed consumer. We realize, though, that a website
            can only do so much, and you may have some questions or want to speak with a human. When that's the case, please complete the form below and we will get back to you as soon as possible.
          </p>
        </div>
        <div className="container flex flex-wrap px-2 py-8 mx-auto sm:flex-no-wrap">
          <div className="relative flex items-end justify-start p-10 overflow-hidden bg-gray-300 rounded-lg lg:w-2/3 md:w-1/2 sm:mr-10">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameBorder="0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=14681%20Midway%20Rd.%20suite%20200%20Addison,%20TX%2075001+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <div className="relative flex flex-wrap py-6 bg-blue-100">
              <div className="pl-6 lg:w-1/3">
                <h5 className="">
                  <HomeOutlined className="pr-2 text-yellow-500 align-middle " />
                  Address
                </h5>
                <p className="text-xs">{siteMetadata.address.companyName}</p>
                <p className="text-xs">{siteMetadata.address.addressLine1}</p>
                <p className="text-xs">
                  {siteMetadata.address.addressLine2}, {siteMetadata.address.state}, {siteMetadata.address.zipCode}
                </p>
              </div>
              <div className="px-6 lg:w-2/3">
                <h5 className="">
                  <MailOutlined className="pr-2 text-yellow-500 align-middle" />
                  Email
                </h5>
                <TCCSupportEmailLink />
                <h5 className="">
                  <PhoneOutlined className="pr-2 text-yellow-500 align-middle" />
                  Phone
                </h5>
                <TCCSupportPhoneLink />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-2 mt-4 ml-2 bg-blue-100 border border-gray-100 rounded-lg lg:w-1/3 md:w-1/2 md:ml-auto md:py-4 md:mt-0">
            <form id="messageForm" className="w-full" onSubmit={handleOnSubmit}>
              <h3 className="">Let's Chat</h3>
              <div className="py-2">
                <Input className="mb-5" type="text" name="fullname" id="nameInput" size="large" placeholder="Name" />
              </div>
              <div className="py-2">
                <Input className="" type="email" name="email" id="emailInput" size="large" placeholder="Email address" />
              </div>
              <div className="py-2">
                <TextArea
                  className="h-32 px-4 py-2 mb-4 text-base bg-white border border-gray-400 rounded outline-none resize-none focus:outline-none focus:border-indigo-500"
                  rows={8}
                  type="text"
                  name="message"
                  id="messageInput"
                  size="large"
                  placeholder="Message"
                ></TextArea>
              </div>
              <div className="py-2">
                <Button
                  className="w-full h-12 px-6 py-2 text-lg text-gray-500 bg-yellow-500 border-yellow-500 rounded focus:outline-none hover:bg-gray-500 hover:text-yellow-500 hover:border-gray-500"
                  htmlType="submit"
                  disabled={serverState.submitting}
                >
                  Send
                </Button>
              </div>
              <p className="py-2 text-xs text-center text-gray-500">
                <RightCircleOutlined className="py-2 pr-1 align-baseline" />
                We won't use your email for anything other than to reply to you
              </p>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};
